<template>
  <div class="contractlist">
    <NavBar :list="navList" name="历史合同"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput
            v-model="form.merchants_stall_name"
            placeholder="输入商户姓名"
          >
            <template slot="pre">商户姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.worker_id"
            clearable
            placeholder="选择终止人"
            class="offset"
          >
            <el-option
              v-for="item in workerList"
              :key="item.user_id"
              :value="item.user_id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="form.starttime"
            class="offset"
            start-placeholder="合同开始时间段"
            value-format="yyyy-MM-dd"
            type="daterange"
            @change="timeSet('start', $event)"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="form.endtime"
            class="offset"
            start-placeholder="合同结束时间段"
            value-format="yyyy-MM-dd"
            type="daterange"
            @change="timeSet('end', $event)"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="getList">
            搜索
            <template slot="preImage">
              <img src="../../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="imported" :accessId="16712">
            导出
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <!-- table -->
      <div class="public-table">
        <el-table
          :data="datalist"
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
          v-loading="loading"
          :span-method="arraySpanMethod"
          height="95%"
        >
          <el-table-column
            label="商户姓名"
            prop="merchants_stall_name"
          ></el-table-column>
          <el-table-column
            label="商户身份证号"
            prop="merchants_card_id"
            width="200px"
          ></el-table-column>
          <el-table-column
            label="商铺号"
            width="200px"
            show-overflow-tooltip
            prop="merchantStallName"
          ></el-table-column>
          <el-table-column
            label="总基础费用"
            prop="total_price"
          ></el-table-column>
          <el-table-column
            label="合同开始时间"
            prop="contract_start"
          ></el-table-column>
          <el-table-column
            label="合同到期时间"
            prop="contract_end"
          ></el-table-column>
          <el-table-column
            label="合同天数"
            prop="contract_reduce"
          ></el-table-column>
          <el-table-column label="终止人" prop="worker_name"></el-table-column>
          <el-table-column
            label="终止时间"
            prop="contract_stop_time"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="slot-box">
                <pop
                  tips="详情"
                  :accessId="80576"
                  @myclick="
                    $openNewTab(
                      `/contract/contractdetail?id=${scope.row.contract_id}&type=his&mId=${scope.row.merchants_id}`
                    )
                  "
                >
                  <img src="../../../unit/img/xq.png" class="icon" alt />
                </pop>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination
          :total="total"
          next-text="下一页"
          prev-text="上一页"
          :current-page="1"
          :page-size="10"
          layout="total,prev,pager,next"
          @current-change="getList"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "历史合同",
        },
      ],
      datalist: [],
      total: 0,
      form: {
        worker_id: "",
        merchants_stall_name: "",
        starttime: "",
        merchantName: "",
        endtime: "",
        contract_status: 1,
      },
      loading: false,
      contractstatus: [
        {
          key: 0,
          title: "待审批",
        },
        {
          key: 1,
          title: "已拒绝",
        },
        {
          key: 2,
          title: "待商户签字",
        },
        {
          key: 3,
          title: "商户已签字",
        },
        {
          key: 4,
          title: "正常",
        },
        {
          key: 5,
          title: "即将到期",
        },
        {
          key: 6,
          title: "已到期",
        },
        {
          key: 7,
          title: "已续签",
        },
      ],
      workerList: [],
    };
  },
  created() {
    this.getList();
    this.getWorkerList();
  },
  methods: {
    getList(e = 1) {
      let arr = [];
      this.form.pageNum = e;
      this.$request
        .HttpGet("/contract/list", this.form)
        .then((res) => {
          this.loading = false;
          let obj = {};
          if (res.data && res.data.list) {
            res.data.list.forEach((item) => {
              item.merchantName = item.merchants_stall_name;
              item.merchantStallName = "";
              if (item.merchants_stall) {
                item.merchants_stall.forEach((items, indexs) => {
                  item.merchantStallName +=
                    indexs < item.merchants_stall.length - 1
                      ? items.merchants_stall_name + ","
                      : items.merchants_stall_name;
                });
              }
              if (!obj[item.merchants_id]) {
                obj[item.merchants_id] = [];
              }
              obj[item.merchants_id].push({
                ...item,
              });
            });

            this.total = res.data.total;
            Object.values(obj).forEach((item) => {
              item.forEach((items, indexs) => {
                if (indexs == item.length - 1) {
                  items.row = item.length;
                } else {
                  items.row = 0;
                }
              });
            });
            arr = Object.values(obj).flat().reverse();
          } else {
            arr = [];
          }
          this.datalist = arr;
        })
        .catch(() => {
          this.datalist = [];
          this.loading = false;
        });
    },
    arraySpanMethod({ row, columnIndex }) {
      if (columnIndex == 0) {
        if (row.row > 0) {
          return {
            rowspan: row.row,
            colspan: 1,
          };
        }
        return [0, 0];
      }
    },
    timeSet(type, date) {
      if (date) {
        type == "start"
          ? (() => {
              this.form.contract_start_start = date[0];
              this.form.contract_start_end = date[1];
            })()
          : (() => {
              this.form.contract_end_start = date[0];
              this.form.contract_end_end = date[1];
            })();
      } else {
        type == "start"
          ? (() => {
              this.form.contract_start_start = "";
              this.form.contract_start_end = "";
            })()
          : (() => {
              this.form.contract_end_start = "";
              this.form.contract_end_end = "";
            })();
      }
    },
    // 获取申请人
    getWorkerList() {
      this.$request.HttpGet("/worker/list").then((res) => {
        if (res.data) {
          this.workerList = res.data.list;
        }
      });
    },
    imported() {
      this.$request
        .HttpPost("/contract/export", this.form, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res]);
          let link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "历史合同列表.xlsx";
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        });
    },
  },
};
</script>
<style lang="scss"></style>
